import React, { Component } from 'react';
import * as typeformEmbed from '@typeform/embed';
import Fade from 'react-reveal/Fade';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  Nav,
  Row,
  NavItem,
  Button,
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import HeaderImageJpg from './img/HeaderImage-1-min.jpg';
import MacScreenPng from './img/Mac screen@2x-min1.png';
import BackgroundSvg from './img/Background4.svg';
import LocalizedLink from '../../../../../components/LocalizedLink';
// import LandingPage from './img/HeaderLandingPage.svg';
// import LandingPage from './img/HeaderBackGround.svg';
// import LandingPage from './img/LandingPageBackGround.svg';
// import LandingPage from './img/HeaderWithWave.svg';
// import MacScreen from './img/MacScreen.svg';
// import IphoneScreen from './img/IphoneScreen.svg';
// import LandingPage from './img/LandingPage.jpg';
import TriDymeIcon from './img/TriDymeIcon.png';
import AskForDemoButton from '../../../../../components/Button/AskForDemo';


export default class Header extends Component {
  render() {
    const {
      link,
      locale
    } = this.props;
    return (
      // <div className="header" style={{backgroundImage: `url(${HeaderImageJpg})`}}>
      <div className="header">
        <img className="headerBackground" src={BackgroundSvg} />
        <Container fluid>
          <Row>
            <Col lg={5}>
              <div className="intro-message">
                <Row>
                  <Fade left>
                    <h1 id="title">
                      TriDyme: Portail personnalisé pour <strong>les Ecoles et les Universités du secteur du BTP</strong>.
                    </h1>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing" />
                </Row>
                <Row>
                  <Fade left>
                    <h5 id="subtitle">
                      Permettez à vos élèves d'avoir la même qualité de formation y compris en distanciel.
                    </h5>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing" />
                </Row>
                <Row>
                  <Col lg={6}>
                    <Fade left>
                      <LocalizedLink className="nav-link" to="/contact/" >
                        <Button
                          className="mainButton"
                          color="primary"
                          size="lg">
                          Nous contacter
                      </Button>
                      </LocalizedLink>
                    </Fade>
                  </Col>
                  <Col lg={6}>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={7}>
              <Fade right>
                <div className="header-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ow83HhOQEXg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}