import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
	Container,
	Row,
	Col,
	InputGroup,
	InputGroupAddon,
	Button,
	Media,
	Label
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import AvantagesSvg from './img/Avantages.svg';
import StructurePng from './img/Architecture1.png';

const Plugins = ({
	link
}) => {
	return (
		<div className="section">
			<Container>
				<Row>
					<Col lg={6}>
						<Fade left>
							<div className="imgContainer">
								<img className="imgContent" src={StructurePng} alt="Sections" />
							</div>
						</Fade>
					</Col>
					<Col lg={6}>
						<Row>
							<Fade right cascade>
								<Media className="app">
									<Media left href="#">
										<FormattedMessage id="section_alt">
											{
												alt => (
													<img
														className="icon"
														src={AvantagesSvg}
														alt={alt}
													/>
												)
											}
										</FormattedMessage>
									</Media>
									<Media body>
										<Media heading>
											<h2 className="label">
												Les Avantages
											</h2>
										</Media>
										<Label className="subTitle">
											{`Ecoles & Universités`}
										</Label>
									</Media>
								</Media>
							</Fade>
						</Row>
						<Row>
							<Fade right>
								<p className="lead">
									{`Un outil varié:`}
								</p>
							</Fade>
							<Fade right cascade>
								<ul className="lead">
									<li>
										<strong>Portail personnalisé et sécurisé</strong> accessible aux élèves et aux professeurs
									</li>
									<li>
										<strong>Collaboration en temps réel</strong> facile et efficace permettant le suivi des cours, TP et partiels à distance
									</li>
									<li>
										<strong>Economies importantes</strong> en matériel et en maintenance informatique
									</li>
									<li>
										Applications Web permettant de faire du <strong>calcul de structure par éléments finis et du BIM directement sur Internet</strong>
									</li>
									<li>
										Calculs réalisés et stockés en ligne
									</li>
								</ul>
							</Fade>
						</Row>
						<Row>
							<Col lg={12}>
								<Fade right>
									<Button
										href={link}
										className="negativeButton"
									>
										Essayez
									</Button>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Plugins;

