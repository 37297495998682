import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';


export default class FunctionalitySection extends Component {

  render() {

    const { link } = this.props;
    return (
      <div className="section">
        <Container className="functionnalityContainer">
          {/* <iframe src="https://giphy.com/embed/8L0OMY9Kj1N5u6wdkL" width="480" height="270" frameBorder="0" className="giphy-embed" allowFullScreen>
        </iframe> */}
          <h2 className="title">
            Collaboration enseignants/élèves
          </h2>
          <Row>
            <Col xs={12}>
              <Slide bottom>
                <div className="videoResponsive">
                  <iframe title={`Presentation`} width="560" height="315" src="https://www.youtube.com/embed/w-x_my0dxEo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowFullScreen>
                  </iframe>
                </div>
              </Slide>
            </Col>
          </Row>
          <h3 className="subtitle">
            Travailler ensemble à distance
          </h3>
          <p className="lead">
            Tout comme pour l’utilisation de la plateforme pour le suivi des cours, il est possible de travailler sur des travaux pratiques et des projets en groupe. Les élèves peuvent partager leur travail avec le professeur qui peut ainsi les aider en temps réel.
          </p>
          <h3 className="subtitle">
            Évaluer à distance
          </h3>
          <p className="lead">
            Cette solution permet également de passer des partiels en ligne : de la même façon que les TP, les élèves peuvent accéder à l’examen. Le professeur recevra ainsi le rendu final des exercices de chacun des élèves et pourra également les corriger directement au sein des applications.
          </p>
        </Container>
      </div>
    )
  }
}