import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import * as typeformEmbed from '@typeform/embed';
import InterWaveSvg from './img/InterWave.svg';
import WaveSvg from './img/Wave.svg';
import ESITCParisPng from './img/ESITCParis1.png';
import ESITCMetzPng from './img/ESITCMetz.png';
import SolideoJpg from './img/Solideo.jpg';
import datBIMJpg from './img/datBIM.png';
import HDSIJpg from './img/HDSI.jpg';
import ImpulsePartnersPng from './img/ImpulsePartners.png';
import BatisseurDAvenirPng from './img/BatisseurDAvenir.png';
import BuildingSmartPng from './img/buildingSmart.png';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  avatar: {
    boxShadow: "3px 3px 20px rgba(0, 0, 0, .6)",
    height: "100px",
    width: "100px",
    backgroundColor: 'white',
    margin: 'auto'
  },
  img: {
    maxWidth: "90px",
    height: "auto"
  }
}));

const referenceList = [
  {
    href: "https://www.esitc-paris.fr/fr",
    id: "reference.esitc.paris",
    src: ESITCParisPng
  },
  {
    href: "http://batisseursdavenir.fr/" ,
    id: "reference.batisseurdavenir",
    src: BatisseurDAvenirPng
  },
  {
    href: "http://www.esitc-metz.com/" ,
    id: "reference.esitc.metz",
    src: ESITCMetzPng
  },
  {
    href: "https://www.ouvrages-olympiques.fr/" ,
    id: "reference.solideo",
    src: SolideoJpg
  },
  {
    href: "https://www.datbim.com/" ,
    id: "reference.datbim",
    src: datBIMJpg
  },
  {
    href: "https://impulse-partners.com/",
    id: "reference.impulsepartners",
    src: ImpulsePartnersPng
  },
  {
    href: "https://www.hdsi.asso.fr/",
    id: "reference.buildingsmart",
    src: BuildingSmartPng
  },
  {
    href: "https://www.buildingsmartfrance-mediaconstruct.fr/",
    id: "reference.buildingsmart",
    src: HDSIJpg
  },
];

const ReferenceSection = ({
  link
}) => {
  const classes = useStyles();

  return (
    <div className="referenceSection">
      <Container className="referenceContainer">
        <h2 className="title">
          Ils nous font confiance
        </h2>
        <br />
        <div className={classes.root}>
        <Grid container spacing={3}>
          { referenceList.map((reference, index) => (
            <Grid item xs={3}>
              <Avatar className={classes.avatar} key={index}>
                <a href={reference.href} target="_blank">
                  <FormattedMessage id={reference.id}>
                    {
                      alt =>  (
                        <img 
                          className={classes.img}
                          src={reference.src} 
                          alt={alt}
                        />
                      )
                    }
                  </FormattedMessage>
                </a>
              </Avatar>
            </Grid>
          ))}
        </Grid>
      </div>
      </Container>
    </div>
  );
}

export default ReferenceSection;
