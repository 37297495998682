import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Ecoles from '../scenes/LandingPages/Ecoles';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Schools`,
    description: "Construction Web Apps for Schools"
  },
  fr: {
    title: `TriDyme | Ecoles`,
    description: " TriDyme: Portail personnalisé pour les Ecoles et les Universités du secteur du BTP."
  }
};

const LandingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta
      locale={locale}
      metaData={metaData}
    />
    <Ecoles />
  </Layout>
)

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LandingPage;