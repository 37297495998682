import React, { Component } from 'react';
import Header from './scenes/Header';
import FonctionalitySection from './scenes/FonctionalitySection';
import ReferenceSection from './scenes/ReferenceSection';
import Plugins from './scenes/Plugins';
import Fonctionnement from './scenes/Fonctionnement';

const {
  REACT_APP_URL
} = process.env;

export default class Home extends Component{
  render(){
    const linkToApp = "https://app.tridyme.com";
    return(
      <div className="home">
        <Header link={linkToApp}/>
        <FonctionalitySection link={linkToApp}/>
        <Plugins link={linkToApp}/>
        <Fonctionnement link={linkToApp}/>
        <ReferenceSection link={linkToApp}/>
      </div>
    )
  }
}