import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
	Container,
	Row,
	Col,
	Button,
	Media,
	Label
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import ProcessSvg from './img/Process.svg';
import ConcreteSectionStressJpg from './img/ConcreteSectionGeometry.jpg';

const Fonctionnement = ({
	link
}) => {
	return (
		<div className="section">
			<Container>
				<Row>

					<Col lg={12}>
						<Row>
							<Fade left cascade>
								<Media className="app">
									<Media left href="#">
										<FormattedMessage id="section_alt">
											{
												alt => (
													<img
														className="icon"
														src={ProcessSvg}
														alt={alt}
													/>
												)
											}
										</FormattedMessage>
									</Media>
									<Media body>
										<Media heading>
											<h2 className="label">
												Le Fonctionnement
											</h2>
										</Media>
										<Label className="subTitle">
											{`Ecoles & Universités`}
										</Label>
									</Media>
								</Media>
							</Fade>
						</Row>
						<Row>
							<Fade left>
								<p className="lead">
									La Marketplace TriDyme propose diverses applications Web de calcul à destination des bureaux d'études, comme de la modélisation 3D, du calcul de structure,..
								</p>
								<p className="lead">
									Elle est directement accessible en ligne, aucune installation n'est requise. De plus, la puissance du cloud permet de réaliser d'importants calculs simultanément, mais également de les stocker sans limite.
								</p>
							</Fade>
						</Row>
						<Row>
							<Fade left>
								<p className="lead">
									TriDyme propose aux écoles de développer leur portail privé et ainsi de disposer de leur propre url et applications.
								</p>
								<p className="lead">
									L'ensemble des professeurs et élèves ont leur identifiant et mot de passe et peuvent se connecter à la plateforme et accéder facilement aux différentes applications.
								</p>
								<p className="lead">
									Les enseignants peuvent donc faire cours à distance en réalisant leurs démonstrations directement au sein du portail.
								</p>
							</Fade>
						</Row>
						<Row>
							<Col lg={12}>
								<Fade left className>
									<Button
										href={link}
										className="negativeButton"
									>
										Essayez
                  </Button>
								</Fade>
							</Col>
						</Row>
					</Col>
					{/* <Col lg={6}>
            <Fade right>
              <div className="imgContainer">
                <img className="imgContent" src={ConcreteSectionStressJpg} alt="Sections" /> 
              </div>
            </Fade>
					</Col> */}
				</Row>
			</Container>
		</div>
	);
};

export default Fonctionnement;
